* {
  padding: 0;
  margin: 0;
}

html,
body {
  background: #e5e5e5;
  font-family: "Open Sans", sans-serif;
  color: #333;
}

.container {
  margin: 0 auto;
  max-width: 605px;
  border: 1px solid #cccccc;
  max-height: calc(100vh + min(45px, 12vw));
  display: flex;
  flex-direction: column;
  position: relative;
}

.hidden {
  display: none;
}

.svg-icon {
  width: 20px;
  height: 20px;
}

button,
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 2px;
  text-transform: capitalize;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
}

button:hover,
.button:hover {
  border-color: #cdd;
}

.copy-link {
  padding-left: 30px;
  padding-right: 30px;
}

/* this is used on both the share and the register popups to
  prevent the user from tapping on something else. */

.popup-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s linear;
}

.show-register .popup-background, .show-share .popup-background {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 10;
}

.share-dialog {
  position: absolute;
  top: -999em;
  left: 50%;
  transform: translate(-50%, -45%);
  width: 95%;
  max-width: 500px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: -1;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.3s linear, transform 0.25s ease;
}

.show-share .share-dialog {
  z-index: 20;
  top: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
}

.share-dialog .svg-icon {
  margin-right: 7px;
}

.share-dialog header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.targets {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.close-share,
.close-register {
  position: absolute;
  top: min(18px, 5vw);
  /* breaks at 360px */
  right: min(18px, 5vw);
  cursor: pointer;
  width: 22px;
  height: 22px;
  background: no-repeat center/17.5px 17.5px url("close.d017a93b.svg");
  font-size: 0;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
}

.pen-url {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.video-content {
  position: relative;
  display: flex;
  background: black;
  overflow: hidden;
}

.video-content::before {
  content: "";
  padding-top: 177.77%;
  /* 16/9 ratio */
}

.video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-js .vjs-tech {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-js .vjs-control-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.vjs-loading-spinner {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.85;
  text-align: left;
  border: 6px solid rgba(43, 51, 63, 0.7);
  box-sizing: border-box;
  background-clip: padding-box;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  visibility: hidden;
}

.vjs-loading-spinner:before, .vjs-loading-spinner:after {
  content: "";
  position: absolute;
  margin: -6px;
  opacity: 1;
  border-color: transparent;
  border-top-color: white;
}

.vjs-seeking .vjs-loading-spinner, .vjs-waiting .vjs-loading-spinner {
  display: block;
  animation: vjs-spinner-show 0s linear 0.3s forwards;
}

.vjs-seeking .vjs-loading-spinner:before, .vjs-seeking .vjs-loading-spinner:after, .vjs-waiting .vjs-loading-spinner:before, .vjs-waiting .vjs-loading-spinner:after {
  animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
}

.vjs-seeking .vjs-loading-spinner:before, .vjs-waiting .vjs-loading-spinner:before {
  border-top-color: white;
}

.vjs-seeking .vjs-loading-spinner:after, .vjs-waiting .vjs-loading-spinner:after {
  border-top-color: white;
  animation-delay: 0.44s;
}

video[poster] {
  object-fit: cover;
}

.vjs-poster {
  background-size: cover;
  background-position: inherit;
}

.scrub-container {
  position: absolute;
  right: min(95px, 17.333vw);
  /*65px for screen width 375px*/
  left: min(95px, 17.333vw);
  /*65px for screen width 375px*/
  bottom: min(40px, 5.333vw);
  display: flex;
  align-items: center;
  height: 30px;
  cursor: pointer;
  z-index: 2;
}

.scrub-bar {
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  height: 4px;
  width: 100%;
  border-radius: 2px;
}

.scrub-progress {
  position: absolute;
  left: min(0px, 15vw);
  height: 4px;
  background-color: white;
  border-radius: 2px;
  z-index: 1;
}

.buffer-progress {
  position: absolute;
  left: min(0px, 15vw);
  height: 4px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
}

.video-content .video-recommendations {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  background-color: black;
  color: white;
  justify-content: center;
  z-index: 1;
}

.video-content .video-recommendations h2 {
  margin-left: 80px;
  font-size: 20px;
  text-align: left;
}

@media (max-width: 768px) {
  .video-content .video-recommendations h2 {
    margin-left: 60px;
  }
}

@media (max-width: 375px) {
  .video-content .video-recommendations h2 {
    margin-left: 45px;
  }
}

@media (max-width: 280px) {
  .video-content .video-recommendations h2 {
    margin-left: 30px;
  }
}

.video-recommendations .video-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px 90px 0px 90px;
  justify-content: center;
}

.video-recommendations .video-list a {
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .video-recommendations .video-list {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 60px;
  }
}

@media (max-width: 375px) {
  .video-recommendations .video-list {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 45px;
  }
}

@media (max-width: 280px) {
  .video-recommendations .video-list {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 30px;
  }
}

.video-recommendations h2 {
  color: white;
  font-size: 24px;
  z-index: 2;
}

.video-list-item {
  display: block;
  margin: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .video-list-item {
    display: flex;
    margin: 10px;
    margin-left: 0px;
  }
}

@media (max-width: 375px) {
  .video-list-item {
    display: flex;
    margin: 10px;
    margin-left: 0px;
  }
}

.video-list-item:hover {
  transform: scale(1.1);
}

.video-list-item img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .video-list-item img {
    height: 75px;
    width: 75px;
    margin-bottom: 0px;
    margin-right: 20px;
  }
}

@media (max-width: 375px) {
  .video-list-item img {
    height: 50px;
    width: 50px;
    margin-bottom: 0px;
    margin-right: 10px;
  }
}

.video-list-item p {
  font-size: 12px;
  align-self: center;
  color: #ffffff;
  text-decoration: none;
}

.video-list-item a {
  color: #ffffff;
  text-decoration: none;
}

.video-content .play-pause {
  position: absolute;
  bottom: min(40px, 5.333vw);
  left: min(45px, 5.333vw);
  background: no-repeat center/contain url("play-pause-replay.954b1a17.svg#play-view");
  width: 30px;
  height: 30px;
  z-index: 2;
}

.video-content .paused {
  background: no-repeat center/contain url("play-pause-replay.954b1a17.svg#pause-view");
}

.video-content .replay {
  background: no-repeat center/contain url("play-pause-replay.954b1a17.svg#replay-view");
}

.video-content .action-mute {
  position: absolute;
  top: min(27px, 6.925vw);
  left: min(45px, 5.333vw);
  background: no-repeat url("volume.e114a4e0.svg#unmute-view");
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  z-index: 2;
}

.video-content .muted {
  background: no-repeat url("volume.e114a4e0.svg#mute-view");
  background-size: 30px 30px;
}

.video-content .action-fullscreen {
  position: absolute;
  top: min(27px, 6.925vw);
  right: min(45px, 5.333vw);
  background: no-repeat url("expand-solid.0a6e3ab3.svg");
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  z-index: 2;
}

.video-content .action-share {
  position: absolute;
  bottom: min(96px, 20.256vw);
  right: min(45px, 5.333vw);
  background: no-repeat center/contain url("share-solid.2a669712.svg");
  height: 30px;
  width: 30px;
  z-index: 2;
}

.video-content .action-like {
  position: absolute;
  bottom: min(40px, 5.333vw);
  right: min(45px, 5.333vw);
  background: no-repeat url("heart.64f06dff.svg#unlike-view");
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  z-index: 2;
}

.video-content .liked {
  background: no-repeat url("heart.64f06dff.svg#like-view");
  background-size: 30px 30px;
}

.video-content .like-loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #0cc9c0;
  width: 25px;
  height: 25px;
  background: none;
  animation: spin 2s linear infinite;
}

.description {
  background: white;
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: min(30px, 5.333vw);
}

.description img {
  margin-right: 20px;
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 8px;
}

.description .description-text {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  font-size: 16px;
}

.description .description-text .caption {
  font-weight: bold;
}

.discover {
  background: white;
  text-align: center;
  padding: min(30px, 5.333vw);
}

.discover a {
  display: flex;
  border-radius: 50px;
  border: none;
  background-color: #0cc9c0;
  color: white;
  text-decoration: none;
  max-width: 375px;
  width: 90%;
  height: 40px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

/* Modal Content */

.register-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  transform: translateY(5%);
  opacity: 0;
  overflow: hidden;
  background: #fff;
  z-index: 20;
  transition: opacity 0.25s linear, transform 0.25s ease;
  /* putting this outside the subscreen so we can restyle it without having to
    resort to a bunch of odd css stuff */
}

.show-register .register-popup {
  height: 100%;
  opacity: 1;
  transform: translateY(0%);
}

.register-popup .subscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.25s ease;
  box-sizing: border-box;
}

.register-popup .subscreen.show {
  transform: translateX(0%);
}

.register-popup .subscreen img {
  display: block;
  margin: 0 auto min(52px, 6.4vh);
  /* 52/812*100 */
}

.register-popup .subscreen h2 {
  font-weight: bold;
  font-size: min(30px, 8vw);
  /* 30/375*100 */
  margin-bottom: min(19px, 5.06vw);
  /* 19/375*100 */
}

.register-popup header {
  background: #fff;
  border-bottom: solid 1px #c7c9ca;
  position: relative;
  text-align: center;
  padding: 17px;
}

.register-popup header .title {
  font-size: 16px;
  font-weight: 600;
}

.register-popup header .back-btn {
  position: absolute;
  top: min(18px, 5vw);
  /* breaks at 360px */
  left: min(18px, 5vw);
  cursor: pointer;
  width: 22px;
  height: 22px;
  background: no-repeat center/10.1px 17.1px url("back-btn.09931074.svg");
  font-size: 0;
}

.register-popup p {
  max-width: 264px;
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto;
}

.register-popup button {
  display: flex;
  height: 38px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: solid 1px #999;
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  width: 345px;
  margin: 0 auto 15px;
}

@media (max-width: 405px) {
  .register-popup button {
    width: 100%;
  }
}

.register-selection {
  overflow: auto;
  padding: min(104px, 12.8vh) min(30px, 8vw) min(30px, 8vw);
  /* 104/812*100, 30/375*100 */
  text-align: center;
}

.register-selection p {
  margin-bottom: min(92px, 11.33vw);
  /* 92/812*100 */
}

.register-buttons {
  display: flex;
  flex-direction: column;
}

.register-buttons .or {
  margin-bottom: 15px;
}

#email-btn {
  border: solid 1px #7381f8;
  background-color: #7381f8;
  color: #fff;
}

#email-btn::before {
  content: "";
  display: inline-block;
  width: 22.236px;
  height: 17.412px;
  margin-right: 13px;
  background: no-repeat center/contain url("email.3d4681b9.svg");
}

#apple-btn {
  border: solid 1px #000;
  background-color: #000;
  color: #fff;
}

#apple-btn::before {
  content: "";
  display: inline-block;
  width: 17.855px;
  height: 21.198px;
  background: no-repeat center/contain url("apple.c7b6e8d2.svg");
  margin: 0 14.7px 4px 0;
}

#google-btn {
  background-color: #fff;
  color: #000;
}

#google-btn::before {
  content: "";
  display: inline-block;
  width: 20.4px;
  height: 20.8px;
  background: no-repeat center/contain url("google.5427a26a.svg");
  margin-right: 15.3px;
}

.fine-print {
  font-size: 12px;
}

.email-register,
.email-thanks {
  background-color: #f7f7f7;
  text-align: center;
  overflow: auto;
  text-align: center;
}

.email-register .main-content,
.email-thanks .main-content {
  padding: min(47px, 5.788vh) min(30px, 8vw) min(30px, 8vw);
  /* 47/812*100 30/375*100 */
}

.email-register p,
.email-thanks p {
  margin-bottom: min(40px, 10.666vw);
  /* 40/375*100 */
}

.email-register input,
.email-thanks input {
  border: solid 1px #fff;
  background-color: #fff;
  width: 345px;
  margin: 0 auto 20px;
  box-sizing: border-box;
  padding: 15px 20px;
  font-size: 16px;
}

@media (max-width: 405px) {
  .email-register input,
.email-thanks input {
    width: 100%;
  }
}

.email-register input.error,
.email-thanks input.error {
  border-color: #fc0202;
}

.email-register button,
.email-thanks button {
  border: solid 1px #7381f8;
  background-color: #7381f8;
  color: #fff;
}

.email-register .email-error,
.email-thanks .email-error {
  color: #fc0202;
  font-size: 16px;
  margin-bottom: min(80px, 9.852vh);
  /* 80/812*100 */
}

.email-register .resent-notice,
.email-thanks .resent-notice {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin: 0 auto min(80px, 9.852vh);
  /* 80/812*100 */
  max-width: 264px;
  opacity: 0;
  transition: opacity 0.25s linear;
  user-select: none;
}

.email-register .resent-notice.sent,
.email-thanks .resent-notice.sent {
  opacity: 1;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #0cc9c0;
  width: 65px;
  height: 65px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.ababbed3.css.map */
